import './index.scss'
import ReactPlayer from 'react-player'


function Videoz() {


      return (
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            url='https://www.youtube.com/watch?v=e4vw23dgWpQ'
            width='100%'
            height='100%'
          />
        </div>
      )


}
export default Videoz
