
export const photos = [

  
  { src: './photos/vesuve.webp',
    width: 3,
    height: 2
  },

  {
    src: "./photos/Colon_le.webp",
    width: 3,
    height: 4
  },

  {
    src: "./photos/cm_berlin4.webp",
    width: 5,
    height: 3
  },

  {
    src: "./photos/Ardechewood.webp",
    width:3,
    height: 2
  },
  
  {
    src: './photos/Andres_streets.webp',
    width: 3,
    height: 2
  },

  {
    src: "./photos/scene of change 4.webp",
    width: 3,
    height: 4
  },
  
  {
    src: './photos/rhythm_music_and_me.webp',
    width: 3,
    height: 2
  },
  

  {
    src: "./photos/scene_of_change_07.webp",
    width: 3,
    height: 4
  },
  
  {
    src: "./photos/Door-of-change2.webp",
    width: 3,
    height: 4
  },
{
  src: './photos/r_evolution2.webp',
  width: 3,
  height: 2
},
{
  src: "./photos/stsepulcre_le.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/Charcuterie_07.webp",
  width: 8,
  height: 3
},
{
  src: "./photos/Arles_VGogh7_le.webp",
  width: 9.5,
  height: 3
},
{
  src: "./photos/Oelkom_01.webp",
  width:2,
  height: 3
},

{
  src: "./photos/detour.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/wayof6_fast_life.webp",
  width: 3,
  height: 2
},
{
  src: './photos/ontheway.webp',
  width: 2,
  height: 3
},

{
  src: "./photos/cm_berlin_23_le.webp",
  width:3,
  height: 2
},

{
  src: "./photos/wayof2_le.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/cm_berlin6.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/Way_of_change.webp",
  width: 2,
  height: 3
},

{
  src: "./photos/Way_of_change1.webp",
  width:2,
  height: 3
},

{
  src: "./photos/Charcuterie_0.webp",
  width:2,
  height: 3
},

{
  src: "./photos/cm_berlin_24_le.webp",
  width:3,
  height: 2
},

{
  src: "./photos/waterworld0.webp",
  width:3,
  height: 2
},

{
  src: "./photos/p_galliera.webp",
  width:3,
  height: 2
},
{
  src: "./photos/plouf03.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/arles en scene_2013.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/streetperpi01.webp",
  width:2,
  height: 3
},

{
  src: "./photos/streetperpi03.webp",
  width:2,
  height: 3
},
{
  src: "./photos/Metro Velasquez_le.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Forca_citadelle.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/entre2monde_r_2016.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/entre_les_murs_r_2016.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/surlaroute3.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Marseille_under_snow.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/sugiton_ecum025.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/mac_lisboa.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/usine51.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/cabanaloup.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/apchon.webp",
  width: 3,
  height: 2
},


{
  src: "./photos/a-piece-of-street.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Wayof2.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/PuyMary.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/sug2.webp",
  width: 2,
  height: 3
},

{
  src: "./photos/sug_001.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/rythm_musicandme.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/entredeux_3.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/entredeux_1.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/wayof_1.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/pano_marseille_4.webp",
  width: 12,
  height: 2
}



];


