import './App.scss';
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import Pho from './components/Pho'
import Videoz from './components/Video'
import Anim from './components/Anim'
import Gen from './components/Gen'
import About from './components/About'
import Contact from './components/Contact'
import index from './components/Pho'





function App() {
  return (
    <>
    <Routes>
      <Route exact path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="pho" activeClassName={index.active} element={<Pho />} />
        <Route path="video" element={<Videoz />} />
        <Route path="anim" element={<Anim />} />
        <Route path="gen" element={<Gen />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
    </>
  )
}

export default App
