export const vign = [

 
  {
    src: "./photos/01_le_8b_i.webp",
    
    width: 3,
    height: 2
  },


  {
    src: "./photos/BAJA_Blimes_Piranha_01_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/03_le_8b_i.webp",
    width: 2,
    height: 3
  },

  {
    src: "./photos/02_le_8b_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/Cyrano_05_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/Cyrano_07_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/plouf00_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/Cyrano_10_i.webp",
    width: 3,
    height: 2
  },

  {
    src: "./photos/chemin_faisant_08_i.webp",
    width:3,
    height: 2
  },{
    src: "./photos/on_air_Malakwa_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/BAJA_Blimes_Piranha_02_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/chemin_faisant_02_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/pinocchio01_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/BAJA_Blimes_Piranha_03_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/plouf01_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/GIMME DANGER_i.webp",
    width: 3,
    height: 2
  },
    
  {
    src: "./photos/BAJA_BIZART_EL_PALO_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/MLK gimme danger_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/La_Replique005_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman2_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/La_Replique014_i.webp",
    width: 3,
    height: 2
  },

  
  {
    src: "./photos/Magic_G_i.webp",
    width: 3,
    height: 2
  },
  
  
  {
    src: "./photos/monster_06_i.webp",
    width: 2,
    height: 3
  },
    
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman3_i.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/monster_05_i.webp",
    width: 2,
    height: 3
  },
  
  {
    src: "./photos/fred_bat_lanef_i.webp",
    width: 2,
    height: 3
  },
  {
    src: "./photos/BAJA_BIZART_EL_PALO_2_i.webp",
    width: 3,
    height: 2
  },

  {
    src: "./photos/livebatt_i.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/segries03_i.webp",
    width: 3,
    height: 2
  },

  
  {
    src: "./photos/BAJA_Yaite_el_palo_i.webp",
    width: 3,
    height: 2
  }
  
  
];

