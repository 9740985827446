
export const vign = [

  
  { src: './photos/vesuve_i.webp',
    width: 3,
    height: 2
  },

  {
    src: "./photos/Colon_le_i.webp",
    width: 3,
    height: 4
  },

  {
    src: "./photos/cm_berlin4_i.webp",
    width: 5,
    height: 3
  },

  {
    src: "./photos/Ardechewood_i.webp",
    width:3,
    height: 2
  },
  
  {
    src: './photos/Andres_streets_i.webp',
    width: 3,
    height: 2
  },

  {
    src: "./photos/scene of change 4_i.webp",
    width: 3,
    height: 4
  },
  
  {
    src: './photos/rhythm_music_and_me_i.webp',
    width: 3,
    height: 2
  },
  

  {
    src: "./photos/scene_of_change_07_i.webp",
    width: 3,
    height: 4
  },
  
  {
    src: "./photos/Door-of-change2_i.webp",
    width: 3,
    height: 4
  },
{
  src: './photos/r_evolution2_i.webp',
  width: 3,
  height: 2
},
{
  src: "./photos/stsepulcre_le_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/Charcuterie_07_i.webp",
  width: 8,
  height: 3
},
{
  src: "./photos/Arles_VGogh7_le_i.webp",
  width: 9.5,
  height: 3
},
{
  src: "./photos/Oelkom_01_i.webp",
  width:2,
  height: 3
},

{
  src: "./photos/detour_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/wayof6_fast_life_i.webp",
  width: 3,
  height: 2
},
{
  src: './photos/ontheway_i.webp',
  width: 2,
  height: 3
},

{
  src: "./photos/cm_berlin_23_le_i.webp",
  width:3,
  height: 2
},

{
  src: "./photos/wayof2_le_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/cm_berlin6_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/Way_of_change_i.webp",
  width: 2,
  height: 3
},

{
  src: "./photos/Way_of_change1_i.webp",
  width:2,
  height: 3
},

{
  src: "./photos/Charcuterie_0_i.webp",
  width:2,
  height: 3
},

{
  src: "./photos/cm_berlin_24_le_i.webp",
  width:3,
  height: 2
},

{
  src: "./photos/waterworld0_i.webp",
  width:3,
  height: 2
},

{
  src: "./photos/p_galliera_i.webp",
  width:3,
  height: 2
},
{
  src: "./photos/plouf03_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/arles en scene_2013_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/streetperpi01_i.webp",
  width:2,
  height: 3
},

{
  src: "./photos/streetperpi03_i.webp",
  width:2,
  height: 3
},
{
  src: "./photos/Metro Velasquez_le_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Forca_citadelle_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/entre2monde_r_2016_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/entre_les_murs_r_2016_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/surlaroute3_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Marseille_under_snow_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/sugiton_ecum025_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/mac_lisboa_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/usine51_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/cabanaloup_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/apchon_i.webp",
  width: 3,
  height: 2
},


{
  src: "./photos/a-piece-of-street_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/Wayof2_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/PuyMary_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/sug2_i.webp",
  width: 2,
  height: 3
},

{
  src: "./photos/sug_001_i.webp",
  width: 2,
  height: 3
},
{
  src: "./photos/rythm_musicandme_i.webp",
  width: 3,
  height: 2
},

{
  src: "./photos/entredeux_3_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/entredeux_1_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/wayof_1_i.webp",
  width: 3,
  height: 2
},
{
  src: "./photos/pano_marseille_4_i.webp",
  width: 12,
  height: 2
}



];


