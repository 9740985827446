import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import vesuve from '../../assets/p_home/mac_lisboa.webp'
import React, { Component } from "react";
import Slider2 from "react-slick";

import ReactPlayer from 'react-player'
import Pho1 from '../Pho1'
import Pho2 from '../Pho2'
import Anim from '../Anim'

const TextBlockserie = () => (
  <div className="text-block" >
    <p>Vous trouverez ici mes travaux photograhiques SERIE </p>
  </div>
);

const TextBlockcolab = () => (
  <div className="text-block" >
    <p>Vous trouverez ici mes travaux photograhiques COLAB </p>
  </div>
);



export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000
    };
    return (
      <div>

        <Slider2 {...settings}>
        <div>
          
        <Pho1 />
        <TextBlockserie />
          </div>

          <div>
          <Pho2 />
          <TextBlockcolab />
            </div>
        <div className="first">
        
          <img src={vesuve} alt=" mac_lisboa " />
          
        </div>

        </Slider2>

      </div>
    );

    
  }
  updateState() {
    // Mettre à jour l'état...
    this.setState({ /* nouveaux états */ });
    this.forceUpdate(); // Forcer actualisation de l'affichage
  }
}


  

