import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import React, { Component } from "react";
import Slider3 from "react-slick";
import ReactPlayer from 'react-player'
import Video1 from '../Video1'
import Video2 from '../Video2'
import Video3 from '../Video3'
import Video4 from '../Video4'

const TextElpalo = () => (
  <div className="text-block">
    <p>EL PALO / BAJA FREQUENCIA & LA DAME BLANCHE 
      <br/>
      Music Video / 2017
    </p>
  </div>
);

const TextCrisis = () => (
  <div className="text-block" >
    <p>CRISIS / BAJA FREQUENCIA & LA DAME BLANCHE 
      <br/>
      Music Video / 2019
    </p>
  </div>
);

const TextLeroiseul = () => (
  <div className="text-block" >
    <p>TRAILER / LE ROI SEUL / R.Giusiano
      <br/>
      Fiction / Court Métrage / 26' / 2019</p>
  </div>
);


const TextBadman = () => (
  <div className="text-block" >
    <p>BADMAN / BAJA FREQUENCIA & SKARRA MUCCI 
      <br/>
      Music Video / 2018</p>
  </div>
);

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 7000
    };
    return (
      <div>
      <Slider3 {...settings}>

        <div>
          <Video4 />
          <TextLeroiseul />
        </div>
        <div>
          <Video3 />
          <TextElpalo />
        </div>
        <div>
          <Video1 />
          <TextCrisis />
        </div>
        <div>
          <Video2 />
          <TextBadman />
        </div>
          
      </Slider3>
      </div>
    );
  }
}
