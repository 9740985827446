


export const photos = [

  
  {
    src: './photos/Gen/Jungle_radio_01.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_02.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_03.webp',
    width: 5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_04.webp',
    width: 3.5,
    height: 2
  },  {
    src: './photos/Gen/Jungle_radio_05.webp',
    width: 5,
    height: 2
  }, {
    src: './photos/Gen/Jungle_radio_07.webp',
    width: 5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_08_v.webp',
    width: 3.5,
    height: 2
  }
  ,
  {
    src: './photos/Gen/Jungle_radio_09_v.webp',
    width: 3.5,
    height: 2
  }
  
  ,
  {
    src: './photos/Gen/Jungle_radio_10_v.webp',
    width: 3.5,
    height: 2
  },
  
  {
    src: './photos/Gen/Jungle_radio_11_v.webp',
    width: 3.5,
    height: 2
  },
  
  {
    src: './photos/Gen/Jungle_radio_12_v.webp',
    width: 3.5,
    height: 2
  }

];




