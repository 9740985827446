import './index.scss'
import ReactPlayer from 'react-player'


function Anim() {


      return (
        <div className='player-wrapper'>
          <ReactPlayer className='react-player'
                playing url='cave1080.mov'
                width='100%'
                height='auto'
                controls='true'
          />
        </div>
      )


}
export default Anim
