


export const vign = [

  
  {
    src: './photos/Gen/Jungle_radio_01_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_02_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_03_i.webp',
    width: 5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_04_i.webp',
    width: 3.5,
    height: 2
  },  {
    src: './photos/Gen/Jungle_radio_05_i.webp',
    width: 4,
    height: 2
  },{
    src: './photos/Gen/Jungle_radio_07_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_08_v.webp',
    width: 3.5,
    height: 2
  }
  ,
  {
    src: './photos/Gen/Jungle_radio_09_v.webp',
    width: 3.5,
    height: 2
  },
  
  {
    src: './photos/Gen/Jungle_radio_10_v.webp',
    width: 3.5,
    height: 2
  },
  
  {
    src: './photos/Gen/Jungle_radio_11_v.webp',
    width: 3.5,
    height: 2
  },
  
  {
    src: './photos/Gen/Jungle_radio_12_v.webp',
    width: 3.5,
    height: 2
  }
];




