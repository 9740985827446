import { Link, NavLink } from 'react-router-dom'
import index from './index.scss'

import { useState } from 'react';
// import LogoPhotol from '../../assets/images/logo_photo_w.svg'
// import LogoVideo from '../../assets/images/logo_cam_w.svg'
// import LogoAnim from '../../assets/images/logo_3d_w.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedin,
  faInstagram,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'



const Sidebar = () => {
  


  return (

  <div className='nav-bar'>
    <Link className='logo' to='/' >
    <text class="logo">ROMAIN GIUSIANO</text>
    <text class="logo" style={{color: 'rgb(71, 71, 71)', paddingLeft: '10px'}}>
     ARTBOOK</text>
    </Link>

    
    <nav>
    <div class="categories">
     <NavLink exact="true" className={({ isActive }) => (isActive ? "pho-link-selected" : "pho-link")} to="/pho" >
    <a href="">PHOTOGRAPHIE</a>
    </NavLink>
    <NavLink exact="true" className={({ isActive }) => (isActive ? "cam-link-selected" : "cam-link")} to="/video">
    <a href="">FILMS</a>
    </NavLink>
    <NavLink exact="true" className={({ isActive }) => (isActive ? "anim-link-selected" : "anim-link")} to="/anim">
    <a href="" >CG ART</a>
    </NavLink>
    <NavLink exact="true" className={({ isActive }) => (isActive ? "gen-link-selected" : "gen-link")} to="/gen">
    <a href="" >GENERATIVE ART</a>
    </NavLink>
    </div>


    <aside style={{ flex: '0 1 auto', position:'fixed',right:'2%' }}>
    <ul className='socio'>
    <li><NavLink exact="true" activeclassname="active" 
    className="about-link" to="/about">
      <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
    </NavLink></li>
    
      <li>
        <a className="about-link"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/romain-giusiano-323806b6/"
          >
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
          </a>
      </li>
      <li>
        <a className="about-link"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/romaingiusiano"
          >
          <FontAwesomeIcon icon={faInstagram} color="#4d4d4e" />
          </a>
      </li>
      <li>
        <a className="about-link"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com"
          >
          <FontAwesomeIcon icon={faFacebook} color="#4d4d4e" />
          </a>
      </li>
    </ul>
    </aside>
    </nav>
    
  </div>
  );
};

export default Sidebar
