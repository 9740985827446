import './index.scss'
import { Link, NavLink } from 'react-router-dom'

import Logo from './Logo'
import React, { Component } from "react";
import Slider from "react-slick";
import photo_accueil from '../../assets/p_home/photo_accueil.webp'
import texturing_accueil from '../../assets/p_home/texturing_accueil.webp'
import generative_accueil from '../../assets/p_home/generative_accueil.webp'
import video_accueil from '../../assets/p_home/video_accueil.webp'
import ReactPlayer from 'react-player'
import Pho1 from '../Pho1'


 

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false ,
      autoplaySpeed: 3000
    };
    return (
      <div >

        <Slider {...settings}>
        <div className="first">
             <div className="text-accueil">
             Je suis Romain Giusiano, artiste visuel polyvalent, je propose mes services dans la <a className="gras" href="/pho">photographie</a>, 
             la <a className="gras" href="/video">réalisation de films et clips, le montage et l'étalonnage</a>, <a className="gras" href="/anim">le surfacing</a> pour des films et séries TV d'animation, 
             ainsi que la <a className="gras" href="/gen">génération d'image et de vidéo par l'IA</a>. Je me passionne pour imaginer et créer des histoires visuelles, 
             et contribuer à donner vie aux personnages et mondes fictifs. 
             Je suis ouvert à discuter avec vous sur votre projet et voir comment je peux y contribuer.
             </div> 
             <div className="grid-container">
               <div className="pho_acc" >
                <Link to="/pho"><img src={photo_accueil} alt=" Accueil Photo" /></Link>
             </div>
             <div className="video_acc" >
                <Link to="/video"><img src={video_accueil} alt=" Accueil Films " /></Link>
             </div>
             <div className="anim_acc" >
                <Link to="/anim"><img src={texturing_accueil} alt=" Accueil Surfacing " /></Link>
             </div>
             <div className="generative_acc" >
                <Link to="/gen"><img src={generative_accueil} alt=" Accueil Generative Art " /></Link>
             </div>
        </div>
        </div>
        

        </Slider>
      </div>
    );
  }
}
