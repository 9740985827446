import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import React, { Component } from "react";
import Slider3 from "react-slick";
import ReactPlayer from 'react-player'
import Anim2 from '../Anim2'
import Anim1 from '../Anim1'

const TextCave = () => (
  <div className="text-block" style={{
    position: 'absolute',
    bottom: 280, // or some other value to position it above the slider
    zIndex: 1,
    color:'red',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: 25,
    paddingRight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: '0px 30px 0px 0px'
  }}>
    <p>Test 
      <br/>
      Unreal Engine 
    </p>
  </div>
);

const TextCrisis = () => (
  <div className="text-block" style={{
    position: 'absolute',
    bottom: 280, // or some other value to position it above the slider
    zIndex: 1,
    color:'red',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: 25,
    paddingRight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: '0px 30px 0px 0px'
  }}>
    <p>CRISIS / BAJA FREQUENCIA & LA DAME BLANCHE 
      <br/>
      Music Video / 2019
    </p>
  </div>
);

const TextCooptroop = () => (
  <div className="text-block" style={{
    position: 'absolute',
    bottom: 280, // or some other value to position it above the slider
    zIndex: 9,
    color:'red',
    fontSize: '20px',
    fontWeight: 'bold',
    padding:10,
    paddingLeft: 25,
    paddingRight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: '0px 30px 0px 0px'
  }}>
    <p>SURFACING / CoopTroop 
      <br/>
      Saison 1 / 11x54 / 2023</p>
  </div>
);


const TextBadman = () => (
  <div className="text-block" style={{
    position: 'absolute',
    bottom: 280, // or some other value to position it above the slider
    zIndex: 9,
    color:'red',
    fontSize: '20px',
    fontWeight: 'bold',
    padding:10,
    paddingLeft: 25,
    paddingRight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: '0px 30px 0px 0px'
  }}>
    <p>BADMAN / BAJA FREQUENCIA & SKARRA MUCCI 
      <br/>
      Music Video / 2018</p>
  </div>
);

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 7000
    };
    return (
      <div>
      <Slider3 {...settings}>

        <div>
          <Anim1 />
          <TextCooptroop />
        </div>
        <div>
          <Anim2 />
          <TextCave />
        </div>
       
          
      </Slider3>
      </div>
    );
  }
}
