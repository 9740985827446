import './index.scss'
import ardechewood from '../../assets/p_home/Ardechewood.jpg'


const TextComp = () => (
  <div className="text-comp" >
    <p><h1>contact@romain-giusiano.com</h1>
<i>Logiciels maitrisés </i>
<br/>
Blender, Substance Painter, Substance Designer, Photoshop,
Rizom, Guerilla Render, DaVinci Resolve, ComfyUI, After Effect, Protools<br/><br/>

<i>Collaborations </i><br/>
TNZPV, Parmi les lucioles, Damned Film, Tambour de soie,
Chinese Man Records, Baja Frequencia, Mouss et Hakim, Malakwa,
Yohji Yamamoto, Kaporal, 
Cie Emmanuel Gat, Collectif La Réplique, 
Cie Après la pluie, Cie Le Souffle
    </p>
  </div>
);


const TextContact = () => (
  <div className="text-contact" >
    
    <p><h1>Démarche Artistique</h1>
    Partant des questionnements soulevés par ma pratique de la peinture, ma passion pour la photographie trouve son origine dans la capacité qu'elle offre à redessiner une époque, un espace ; à en donner ma propre interprétation à travers le rythme, la couleur et la structure de l'image.
<br/><br/>
La thématique de mon travail est toujours liée aux traces, empreintes, marques laissées par le temps ou par l'Homme. Vieilles ruelles, murs lézardés, friches industrielles : en les photographiant, je cherche à montrer que la vie y est toujours présente, au-delà d'un simple témoignage d'instants passés.
<br/><br/>
Dans mes photos, c'est le contraste constant entre ombre et lumière qui met en évidence cette vie. La manière dont je traite la lumière s'inspire des gloires si souvent représentées en peinture. Eclat épique, céleste, le côté inaltérable de la lumière s'oppose à la déliquescence des lieux où l'Homme est intervenu. 
<br/><br/>
Que ce soit en montrant l'urbanisation des grandes villes ou la pureté de paysages encore vierges, mon travail parle du combat perpétuel entre l'Homme et la Nature : celle-ci envahissant les façades des grandes villes, celui-là imposant sa marque de plus en plus aseptisée dans le réaménagement de vieux quartiers. A chaque fois, la question soulevée pourrait être : « où est la vie ? ». Tant il me semble que sa beauté est toujours à côté de là où l'on croirait d'abord la trouver.
<br/><br/>
Car tout est une question de point de vue. Et la photographie permet également de révéler la poésie des choses sombres, vieilles, délabrées. Le tone mapping aide d'ailleurs à cela, en mettant en évidence une autre réalité : il ne faut pas avoir peur de l'ombre, elle fait partie de la vie . Aussi, à travers des effets surréalistes, parfois proches du rêve, cette technique ouvre la porte à notre imaginaire pour découvrir que l'on peut appréhender le monde autrement.
 
    </p>
  </div>
);

const About = () => {

  return (
    <div className="first-contact">
    <img src={ardechewood} alt=" AWood " />
    <TextContact />
    <TextComp />
       </div>
  );
}
export default About
