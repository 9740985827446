import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import React, { Component } from "react";
import Slider3 from "react-slick";
import ReactPlayer from 'react-player'
import Video1 from '../Video1'
import Video2 from '../Video2'
import Video3 from '../Video3'
import Gen1 from '../Gen1'


const TextGen1 = () => (
  <div className="text-block" style={{
    position: 'absolute',
    bottom: 280, // or some other value to position it above the slider
    zIndex: 1,
    color:'red',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: 25,
    paddingRight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: '0px 30px 0px 0px'
  }}>
    <p>Génération d'images & vidéo
    <br/>
      à l'aide d'outils IA open source / Logiciels libres
      <br/>
      Stable Diffusion / Flux / ComfyUI 
    </p>
  </div>
);



export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000
    };
    return (
      <div>
      <Slider3 {...settings}>
      <div>
      <Gen1/>
      <TextGen1/>
      </div>
         
      </Slider3>
      </div>
    );
  }
}

