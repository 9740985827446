import './index.scss'



const Contact = () => {

  return (
    <div className="first">
    
    </div>
  );
}
export default Contact
