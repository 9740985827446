import './index.scss'
import ReactPlayer from 'react-player'


function Anim() {


  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url='https://vimeo.com/989153508'
        width='100%'
        height='100%'
        controls='true'


      />
    </div>
  )


}
export default Anim
