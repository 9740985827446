export const photos = [

 
  {
    src: "./photos/01_le_8b.webp",
    
    width: 3,
    height: 2
  },


  {
    src: "./photos/BAJA_Blimes_Piranha_01.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/03_le_8b.webp",
    width: 2,
    height: 3
  },

  {
    src: "./photos/02_le_8b.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/Cyrano_05.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/Cyrano_07.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/plouf00.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/Cyrano_10.webp",
    width: 3,
    height: 2
  },

  {
    src: "./photos/chemin_faisant_08.webp",
    width:3,
    height: 2
  },{
    src: "./photos/on_air_Malakwa.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/BAJA_Blimes_Piranha_02.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/chemin_faisant_02.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/pinocchio01.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/BAJA_Blimes_Piranha_03.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/plouf01.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/GIMME DANGER.webp",
    width: 3,
    height: 2
  },
    
  {
    src: "./photos/BAJA_BIZART_EL_PALO.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/MLK gimme danger.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/La_Replique005.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman2.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/La_Replique014.webp",
    width: 3,
    height: 2
  },

  
  {
    src: "./photos/Magic_G.webp",
    width: 3,
    height: 2
  },
  
  
  {
    src: "./photos/monster_06.webp",
    width: 2,
    height: 3
  },
    
  {
    src: "./photos/BAJA_SKARRA_MUCHI_Badman3.webp",
    width: 3,
    height: 2
  },
  {
    src: "./photos/monster_05.webp",
    width: 2,
    height: 3
  },
  
  {
    src: "./photos/fred_bat_lanef.webp",
    width: 2,
    height: 3
  },
  {
    src: "./photos/BAJA_BIZART_EL_PALO_2.webp",
    width: 3,
    height: 2
  },

  {
    src: "./photos/livebatt.webp",
    width: 3,
    height: 2
  },
  
  {
    src: "./photos/segries03.webp",
    width: 3,
    height: 2
  },

  
  {
    src: "./photos/BAJA_Yaite_el_palo.webp",
    width: 3,
    height: 2
  }
  
  
];

